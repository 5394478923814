<template>
    <div class="p-1">
        <b-row v-if="loaded">
            <b-col
                cols="12"
                md="8"
                offset-md="2"
            >
                <b-card no-body>
                    <b-card-header>
                        <a
                            :href="company.PublicUrl"
                            class="comp-logo-link"
                            target="_blank"
                        >
                            <b-img
                                v-if="company.LogoUrl"
                                :src="company.LogoUrl"
                                class="comp-logo pl-1"
                            />
                        </a>
                        <a
                            :href="company.PublicUrl"
                            target="_blank"
                        >
                            <h4>{{ company.Label }}</h4>
                        </a>
                        <div class="
                            comp-links
                            d-flex
                            justify-content-center
                            align-items-center
                          ">
                            <div style="min-width: 150px">
                                <b-form-select
                                    v-model="locale"
                                    :options="cultures"
                                    label="text"
                                />
                            </div>
                            <div>
                                <feather-icon
                                    v-if="company.Website"
                                    class="cursor-pointer"
                                    color="primary"
                                    icon="GlobeIcon"
                                    @click="openLink(company.Website)"
                                />
                            </div>
                            <div>
                                <feather-icon
                                    v-if="company.Linkedin"
                                    class="cursor-pointer"
                                    color="primary"
                                    icon="LinkedinIcon"
                                    @click="openLink(company.Linkedin)"
                                />
                            </div>
                        </div>
                    </b-card-header>

                    <b-card-body>
                        <b-row class="border-bottom p-1">
                            <b-col cols="12" md="8">
                                <h2>{{ offerLabel }}</h2>
                                <h4>
                                    {{ offerContractType ? offerContractType.text : "" }} ·
                                    {{ offerCity }} · {{ offerCountry ? offerCountry.text : "" }}
                                </h4>
                            </b-col>
                        </b-row>

                        <b-row class="row border-bottom p-1">
                            <b-col
                                cols="12"
                                md="5"
                            >
                                <!-- -->
                                <h6 class="section-label">{{ i18nT(`Offer ID`) }}</h6>
                                <div class="detail-field pb-2">
                                    {{ offer.Id }}
                                </div>

                                <h6 class="section-label">{{ i18nT(`Published`) }}</h6>
                                <div class="detail-field pb-2">
                                    {{ offer.CreatedAt | date }}
                                </div>

                                <h6 class="section-label">{{ i18nT(`Location`) }}</h6>
                                <div class="detail-field pb-2">
                                    {{
                                        offer.Address ? offer.Address + ', ' : ''
                                    }}{{ offer.City ? offer.City + ', ' : '' }}{{ getNameByCountryId(offer.Country) }}
                                </div>

                                <h6 class="section-label">{{ i18nT(`Contract type`) }}</h6>
                                <div class="detail-field pb-2">
                                    {{ getNameByContractTypeId(offer.ContractType) }}
                                    {{ offer.Remote ? "- Remote" : "- No remote" }}
                                </div>

                                <h6 class="section-label">{{ i18nT(`Job category`) }}</h6>
                                <div class="detail-field pb-2">
                                    {{ getNameByCategoryId(offer.Category) }}
                                </div>

                                <h6 class="section-label">{{ i18nT(`Department`) }}</h6>
                                <div class="detail-field pb-2">
                                    {{ getNameByDepartmentId(offer.Department) }}
                                </div>

                                <h6
                                    v-if="offerRequiredSkills"
                                    class="section-label"
                                >
                                    {{ i18nT(`Required Skills`) }}
                                </h6>
                                <div class="detail-field pb-2">
                                    <b-badge
                                        class="mr-1"
                                        pill
                                        variant="light-primary"
                                        v-for="requireSkill in offerRequiredSkills"
                                        :key="requireSkill"
                                    >
                                        {{ requireSkill }}
                                    </b-badge>
                                </div>

                                <h6 class="section-label">{{ i18nT(`Seniority Level`) }}</h6>
                                <div class="detail-field pb-2">
                                    {{ getNameSeniorityLevelId(offer.SeniorityLevel) }}
                                </div>

                                <h6
                                    v-if="!offer.SalaryConfidential"
                                    class="section-label"
                                >
                                    {{ i18nT(`Salary range`) }}
                                </h6>
                                <div
                                    v-if="!offer.SalaryConfidential"
                                    class="detail-field pb-2"
                                >
                                    {{ {amount: offer.SalaryFrom, currency: offer.Currency} | currency}} - {{ {amount: offer.SalaryTo, currency: offer.Currency} | currency}} / {{offer.SalaryFrequency}}
                                </div>

                                <h6
                                    v-if="offer.SponsorVisa"
                                    class="section-label"
                                >
                                    {{ i18nT(`Foreign Applicants`) }}
                                </h6>
                                <div
                                    v-if="offer.SponsorVisa"
                                    class="detail-field pb-2"
                                >
                                    {{ i18nT(`Company is willing to sponsor visa`) }}
                                </div>

                                <h6
                                    v-if="offer.EquityFrom"
                                    class="section-label"
                                >
                                    {{ i18nT(`Equity`) }}
                                </h6>
                                <div
                                    v-if="offer.EquityFrom"
                                    class="detail-field pb-2"
                                >
                                    {{ offer.EquityFrom }} - {{ offer.EquityTo }}
                                </div>

                                <h6
                                    v-if="offer.Benefits"
                                    class="section-label"
                                >
                                    {{ i18nT(`Benefits`) }}
                                </h6>
                                <p
                                    v-if="offer.Benefits"
                                    class="detail-field pb-2"
                                    v-html="offer.Benefits"
                                >
                                </p>
                            </b-col>
                            <b-col
                                cols="12"
                                md="7"
                            >
                                <h4>{{ i18nT(`Job description`) }}</h4>
                                <div class="detail-field pb-2">
                                    <p v-html="offer.Description"/>
                                </div>

                                <h4 v-if="offer.Additional">{{ i18nT(`Required profile`) }}</h4>
                                <div
                                    v-if="offer.Additional"
                                    class="detail-field pb-2"
                                >
                                    <p v-html="offer.Additional"/>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row v-if="hasApplied">
                            <b-col cols="12">
                                <div class="w-100 text-center pt-2">
                                    <span class="text-danger">{{ i18nT(`You have already applied for this job`) }}</span>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row
                            v-if="!userType ||
                                  (userType.toLowerCase() !== 'client' && userType.toLowerCase() !== 'staff' && userType.toLowerCase() !== 'employee')
                                  && hasApplied === false
                            ">
                            <div style="flex: 1"/>
                            <b-button
                                variant="primary"
                                class="mt-0 mt-md-2 ml-1 mr-1"
                                v-b-modal.modal-apply
                            >
                                {{ i18nT(`Apply`) }}
                            </b-button>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-modal
            id="modal-apply"
            :title="i18nT(`Apply for this job`)"
            ok-only
            :ok-title="i18nT(`Send Application`)"
            no-close-on-backdrop
            @ok="validationForm"
            hide-footer
        >
            <b-tabs class="rounded">
                <b-tab :active="offerTab">
                    <template #title>
                        <feather-icon
                            icon="BookIcon"
                            size="18"
                            class="mr-1"
                        />
                        <span>{{ i18nT(`Apply now`) }}</span>
                    </template>

                    <validation-observer ref="simpleRules">

                        <!-- PROFILE PREVIEW IF LOGGED-IN -->
                        <div v-if="loggedIn" class="mb-2">
                            <h4 class="mb-1">{{ i18nT(`Your profile`) }}</h4>
                            <avatar-control :profileData="previewProfile" :no-bottom-margin="true"></avatar-control>
                            <p class="mb-0 mt-50">{{ previewProfile.Position }}</p>
                            <p class="mb-0 mt-50">
                                <small v-if="previewProfile.Nationality" class="text-muted">
                                    <font-awesome-icon :icon="['fas', 'globe']"  style="margin-right: 2px;"/> {{ i18nT(previewProfile.Nationality) | capFirst }} |
                                </small>

                                <small v-if="previewProfile.Gender" class="text-muted">
                                    <font-awesome-icon :icon="['fas', 'venus-mars']"  style="margin-right: 2px;"/>{{ i18nT(previewProfile.Gender) | capFirst }} |
                                </small>

                                <small v-if="previewProfile.BirthDate" class="text-muted">
                                    <font-awesome-icon :icon="['fas', 'birthday-cake']" style="margin-right: 2px;"/> {{ previewProfile.BirthDate | date }}
                                </small>
                            </p>
                        </div>



                        <!-- FORM IF NOT LOGGED-IN -->
                        <div v-if="!loggedIn" class="mb-2">
                            <h4>{{ i18nT(`Personal details`) }}</h4>
                            <b-form class="mt-2">
                                <b-row>
                                    <b-col sm="6">
                                        <b-form-group
                                            :label="i18nT(`First name`)"
                                            label-for="firstName"
                                            class="required"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                name="First Name"
                                                rules="required"
                                            >
                                                <b-form-input
                                                    v-model="personalDetails.FirstName"
                                                    :placeholder="i18nT(`John`)"
                                                    name="firstName"
                                                    :state="errors.length > 0 ? false : null"
                                                />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col sm="6">
                                        <b-form-group
                                            :label="i18nT(`Last name`)"
                                            label-for="lastName"
                                            class="required"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                name="Last Name"
                                                rules="required"
                                            >
                                                <b-form-input
                                                    v-model="personalDetails.LastName"
                                                    :placeholder="i18nT(`John`)"
                                                    name="lastName"
                                                    :state="errors.length > 0 ? false : null"
                                                />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="i18nT(`E-mail`)"
                                            label-for="email"
                                            class="required"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                name="E-Mail"
                                                rules="required"
                                            >
                                                <b-form-input
                                                    v-model="personalDetails.Email"
                                                    :placeholder="i18nT(`email@email.com`)"
                                                    name="email"
                                                    :state="errors.length > 0 ? false : null"
                                                />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </div>

                        <!-- Header if needs CV or CoverLetter -->
                        <h4 v-if="offer.NeedCoverLetter || offer.NeedPortfolio" class="my-1">
                            {{ i18nT(`Required attachments`) }}
                        </h4>

                        <!-- Needs CV -->
                        <div
                            v-if="offer.NeedPortfolio"
                            class="mb-1 border-bottom"
                        >
                            <b-form-group
                                :label="i18nT(`CV / Resume`)"
                                label-for="cv"
                                class="required"
                            >
                                <b-tabs class="border rounded px-1">
                                    <b-tab :active="hasPortfolioFile">
                                        <template #title>
                                            <feather-icon
                                                icon="UploadCloudIcon"
                                                size="18"
                                                style="margin-right: 8px"
                                            />
                                            <span>{{ i18nT(`File`) }}</span>
                                        </template>
                                        <div>
                                            <div>
                                                <b-button
                                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                    variant="outline-primary"
                                                    class="mr-1 mb-2"
                                                    @click="onPortfolioFilePick"
                                                >
                                                    <feather-icon icon="PlusCircleIcon"/>
                                                    {{ addCvBtnLabel }}
                                                </b-button>
                                                <b-form-file
                                                    v-model="portfolioFile"
                                                    ref="portfolioFilePicker"
                                                    class="hidden"
                                                />
                                                <span
                                                    class="d-block mb-1"
                                                    v-if="portfolioFileName">
                                                    <strong class="mr-2">{{ portfolioFileName }}</strong>
                                                  <feather-icon
                                                      icon="Trash2Icon"
                                                      size="16"
                                                      @click="onPortfolioFileDelete"
                                                  />
                                                </span>
                                                <span v-if="!portfolioFile">
                                                  {{ portfolioOrignalFileName }}
                                                </span>
                                            </div>
                                            <p v-if="!portfolioFileName">
                                                {{ i18nT(`Attach images, text files or pdf documents.`) }}
                                            </p>
                                        </div>
                                    </b-tab>
                                    <b-tab :active="hasPortfolioUrl">
                                        <template #title>
                                            <feather-icon
                                                icon="LinkIcon"
                                                size="18"
                                                style="margin-right: 8px"
                                            />
                                            <span>{{ i18nT(`URL link`) }}</span>
                                        </template>
                                        <div>
                                            <b-input-group>
                                                <template #prepend>
                                                    <b-input-group-text>
                                                        <feather-icon
                                                            icon="LinkIcon"
                                                            size="18"
                                                        />
                                                    </b-input-group-text>
                                                </template>
                                                <b-form-input
                                                    :placeholder="i18nT(`URL`)"
                                                    v-model="cvLink"
                                                />
                                            </b-input-group>
                                            <p class="pt-2">
                                                {{
                                                    i18nT(`Enter a link to images, text files, spreadsheets, pdf documents and videos.`)
                                                }}
                                            </p>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-form-group>
                        </div>

                        <!-- Needs CoverLetter -->
                        <div
                            v-if="offer.NeedCoverLetter"
                            class="mb-2  border-bottom"
                        >
                            <b-form-group
                                :label="i18nT(`Cover letter`)"
                                label-for="cv"
                                class="required"
                            >
                                <b-tabs class="border rounded px-1">
                                    <b-tab :active="hasFile">
                                        <template #title>
                                            <feather-icon
                                                icon="UploadCloudIcon"
                                                size="18"
                                                style="margin-right: 8px"
                                            />
                                            <span>{{ i18nT(`File`) }}</span>
                                        </template>
                                        <div>
                                            <div>
                                                <b-button
                                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                    variant="outline-primary"
                                                    class="mr-1 mb-2"
                                                    @click="onFilePick"
                                                >
                                                    <feather-icon icon="PlusCircleIcon"/>
                                                    {{ addCoverBtnLabel }}
                                                </b-button>
                                                <b-form-file
                                                    v-model="file"
                                                    ref="filePicker"
                                                    class="hidden"
                                                />

                                                <span class="d-block mb-1" v-if="fileName">
                                                    <strong class="mr-2 ">{{ fileName }}</strong>
                                                    <feather-icon
                                                      icon="Trash2Icon"
                                                      size="16"
                                                      @click="onFileDelete"
                                                    />
                                                </span>
                                                <span v-if="!file">
                                                  {{ originalFileName }}
                                                </span>
                                            </div>
                                            <p v-if="!fileName">{{ i18nT(`Attach images, text files or pdf documents.`) }}</p>
                                        </div>
                                    </b-tab>

                                </b-tabs>
                            </b-form-group>
                        </div>


                        <!-- Questions -->
                        <h4 v-if="offer.Questions && offer.Questions.length > 0" class="mb-1">{{ i18nT(`Additional Questions`) }}</h4>
                        <div
                            v-if="offer.Questions && offer.Questions.length > 0"
                            class="border-bottom mb-2 pb-1">
                            <b-form-group
                                v-for="(question, index) in offer.Questions"
                                :key="index"
                                :label="question.text"
                                label-for="Questions"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="i18nT(`question`)+' '+index"
                                    rules="required"
                                >

                                <b-form-textarea
                                    :id="`Questions`+index"
                                    v-model="offerAnswers[index]"
                                    :placeholder="i18nT(`Type your answer here`)"
                                    :state="errors.length > 0 ? false : null"
                                    rows="3"
                                    max-rows="6"></b-form-textarea>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>

                            </b-form-group>
                        </div>

                        <!-- SOCIAL LINKS -->
                        <div v-if="requiresSocialLinks">
                            <h4 class="my-1">{{ i18nT(`Required social media handles and links`) }}</h4>
                            <!-- YouTube -->
                            <b-form-group
                                :label="i18nT(`Youtube`)"
                                label-for="youtube"
                                :class="`${offerNeedYoutube ? 'required': ''}`"
                                v-if="offerNeedYoutube"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="youtube"
                                    :rules="`${offerNeedYoutube ? 'required': ''}`"
                                >
                                    <b-form-input
                                        v-model="personalDetails.Youtube"
                                        :placeholder="i18nT(`Youtube link`)"
                                        name="youtube"
                                        id="youtube"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <!-- Github -->
                            <b-form-group
                                :label="i18nT(`Github`)"
                                label-for="github"
                                :class="`${offerNeedGithub ? 'required': ''}`"
                                v-if="offerNeedGithub"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="github"
                                    :rules="`${offerNeedGithub ? 'required': ''}`"
                                >
                                    <b-form-input
                                        v-model="personalDetails.Github"
                                        :placeholder="i18nT(`Github link`)"
                                        name="github"
                                        id="github"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <!-- Twitter -->
                            <b-form-group
                                :label="i18nT(`Twitter`)"
                                label-for="twitter"
                                :class="`${offerNeedTwitter ? 'required': ''}`"
                                v-if="offerNeedTwitter"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="twitter"
                                    :rules="`${offerNeedTwitter ? 'required': ''}`"
                                >
                                    <b-form-input
                                        v-model="personalDetails.Twitter"
                                        :placeholder="i18nT(`Twitter link`)"
                                        name="twitter"
                                        id="twitter"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <!-- INSTA -->
                            <b-form-group
                                :label="i18nT(`Instagram`)"
                                label-for="instagram"
                                :class="`${offerNeedInstagram? 'required': ''}`"
                                v-if="offerNeedInstagram"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="instagram"
                                    :rules="`${offerNeedInstagram ? 'required': ''}`"
                                >
                                    <b-form-input
                                        v-model="personalDetails.Instagram"
                                        :placeholder="i18nT(`Instagram link`)"
                                        name="instagram"
                                        id="instagram"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <!-- BEHANCE -->
                            <b-form-group
                                :label="i18nT(`Behance`)"
                                label-for="behance"
                                :class="`${offerNeedBehance ? 'required': ''}`"
                                v-if="offerNeedBehance"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="behance"
                                    :rules="`${offerNeedBehance ? 'required': ''}`"
                                >
                                    <b-form-input
                                        v-model="personalDetails.Behance"
                                        :placeholder="i18nT(`Behance link`)"
                                        name="behance"
                                        id="behance"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <!-- DRIBBLE -->
                            <b-form-group
                                :label="i18nT(`Dribble`)"
                                label-for="dribble"
                                :class="`${offerNeedDribble ? 'required': ''}`"
                                v-if="offerNeedDribble"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="dribble"
                                    :rules="`${offerNeedDribble ? 'required': ''}`"
                                >
                                    <b-form-input
                                        id="dribble"
                                        v-model="personalDetails.Dribble"
                                        :placeholder="i18nT(`Dribble link`)"
                                        name="dribble"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </div>

                        <!-- submit buttons -->
                        <b-button
                            type="button"
                            @click="validationForm"
                            variant="primary"
                            block
                            v-if="!hasApplied"
                        >
                            {{ i18nT(`Send Application`) }}
                        </b-button>

                        <div v-else class="w-100 text-center py-2">
                            <span class="text-danger">{{ i18nT(`You have already applied for this job`) }}</span>
                        </div>

                    </validation-observer>
                </b-tab>
                <b-tab
                    v-if="!loggedIn"
                    :active="loginTab"
                >
                    <template #title>
                        <feather-icon
                            icon="LockIcon"
                            size="18"
                            style="margin-right: 8px"
                        />
                        <span>{{ i18nT(`Login`) }}</span>
                    </template>


                    <login-set @authenticated="afterLogin" :locale="locale" />


                    <b-card-text class="text-center mt-2">
                        <span>{{ i18nT(`New on our platform?`) }} </span>
                        <b-link :to="{ name: 'auth-register' }">
                            <span>{{ i18nT(`Create an account`) }}</span>
                        </b-link>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-modal>
    </div>
</template>

<script>
import {
    BButton,
    BBadge,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupText,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BCardHeader,
    BCardBody,
    BCardText,
    BModal,
    BForm,
    BFormFile,
    BFormTextarea,
    BImg,
    BTabs,
    BTab,
    BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isUserLoggedIn} from '@/auth/utils'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import useAuth from '@/auth/useAuth'

import {dictToSelectArray} from '@core/utils/utils'
import LoginSet from "@/views/common/Login/LoginSet.vue";
import AvatarControl from "@/views/components/avatar/AvatarControl.vue";

export default {
    components: {
        AvatarControl,
        BButton,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BInputGroup,
        BInputGroupText,
        BFormInput,
        BCard,
        BCardHeader,
        BCardBody,
        BCardText,
        LoginSet,
        ValidationProvider,
        ValidationObserver,
        BModal,
        BForm,
        BFormFile,
        BFormTextarea,
        BImg,
        BTabs,
        BTab,
        BLink,
        BFormSelect
    },
    directives: {
        Ripple
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            Deselect: {
                render: createElement => createElement('span', '')
            },

            required,
            loaded: false,
            status: '',
            password: '',
            userEmail: '',
            email,
            profile: {},
            previewProfile: {},
            offer: {},
            company: {
                Label: ''
            },
            document: {},



            offerCategories: [],
            offerContractTypes: [],
            offerSeniorityLevels: [],
            offerDepartments: [],
            offerCountries: [],
            offerCategory: null,
            offerDepartment: [],
            offerContractType: null,
            offerSeniorityLevel: null,
            offerRequiredSkills: [],
            offerId: '',
            offerLabel: '',
            offerDescription: '',
            offerAddress: '',
            offerCity: '',
            offerCountry: '',
            offerSalaryFrom: '',
            offerSalaryTo: '',
            offerEquityFrom: '',
            offerEquityTo: '',
            offerSalaryConfidential: false,
            offerSponsorVisa: false,
            offerRemote: false,
            offerBenefits: '',
            offerAdditional: '',
            offerNeedCoverLetter: false,
            offerNeedPortfolio: false,
            offerNeedYoutube: false,
            offerNeedTwitter: false,
            offerNeedInstagram: false,
            offerNeedBehance: false,
            offerNeedDribble: false,
            offerNeedGithub: false,
            offerAnswers: [],
            questionTypes: [],
            question: null,
            questionType: {value: '1'},
            questionLabel: '',
            questionDescription: '',
            answersData: [''],
            linesCnt: 1,
            possibleAnswers: 1,
            otherOption: false,
            steps: 0,
            stepOptions: [2, 3, 5, 10],
            stepStrings: [null, null, null, null, null, null, null, null, null, null],
            firstStep: '',
            lastStep: '',
            grading: 0,
            personalDetails: {},
            jobOfferId: '',
            cv: null,
            portfolio: null,
            cultures: [],
            locale: 'en',
            hasUrl: false,
            hasFile: false,
            hasPortfolioUrl: false,
            hasPortfolioFile: false,
            loginTab: false,
            offerTab: false,
            file: null,
            cvLink: null,
            fileName: null,
            originalFileName: null,
            portfolioFile: null,
            portfolioFileName: null,
            portfolioFileId: null,
            portfolioOrignalFileName: null,
            portfolioLink: null,
            submitted: false
        }
    },
    watch: {
        locale (val) {
            this.changeLang(val)
            history.pushState({}, null, `${this.$route.path}?lang=${val}`)
        },
        file: function (val) {
            if (val) {
                this.file = val
                this.fileName = val.name
            } else {
                this.file = null
                this.fileName = ''
            }
        },
        portfolioFile: function (val) {
            if (val) {
                this.portfolioFile = val
                this.portfolioFileName = val.name
            } else {
                this.portfolioFile = null
                this.portfolioFileName = ''
            }
        }
    },
    computed: {
        requiresSocialLinks() {
            return (
                this.offerNeedBehance ||
                this.offerNeedDribble ||
                this.offerNeedGithub ||
                this.offerNeedTwitter ||
                this.offerNeedInstagram ||
                this.offerNeedYoutube
            )
        },
        addCvBtnLabel() {
          if(this.portfolioFileName){
              return this.i18nT(`Replace file`);
          } else {
              return this.i18nT(`Add file`);
          }
        },
        addCoverBtnLabel() {
            if(this.fileName){
                return this.i18nT(`Replace file`);
            } else {
                return this.i18nT(`Add file`);
            }
        },
        loggedIn: {
            get() {
                if (this.submitted) return isUserLoggedIn()
                return isUserLoggedIn()
            },
            set(val) {
                useAuth.setToken(val)
            }
        },
        userType: {
            get() {
                return useAuth.getUserType()
            },
            set(val) {
                useAuth.setUserType(val)
            }
        },
        hasApplied() {

            if(this.offer && this.offer.CurrentUserHasApplied)
                return this.offer.CurrentUserHasApplied;
            else {
                return false;
            }

        },
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        }
    },
    created() {
        this.getOfferCategories()
        this.getOfferContractTypes()
        this.getOfferSeniorityLevels()
        this.getOfferDepartments()
        this.getOfferCountries()
        this.getCultures()

        const components = this.$route.fullPath.split('_')
        this.jobOfferId = components[components.length - 1]

        this.fetchOfferData()

        if (this.$route.query.lang) {
            this.locale = this.$route.query.lang
        }
    },
    methods: {
        afterLogin(resp){
            this.loggedIn = resp.data.data.token
            this.userType = resp.data.data.user_type
            this.submitted = true
            this.previewProfile = resp.data.data.menu.user;
            this.fetchOfferData();
        },
        async fetchOfferData(){
            await this.$http
                .get(`offers/publicOffer?id=${this.jobOfferId}`)
                .then(({data}) => {

                    let jobOffer = data.data.offer
                    this.offer = data.data.offer

                    this.company = data.data.company
                    this.offerId = jobOffer.Id
                    this.offerLabel = jobOffer.Label
                    this.offerAddress = jobOffer.Address
                    this.offerCity = jobOffer.City
                    this.offerDescription = jobOffer.Description
                    this.offerBenefits = jobOffer.Benefits
                    this.offerAdditional = jobOffer.Additional

                    this.offerNeedPortfolio =   !!jobOffer.NeedPortfolio
                    this.offerNeedCoverLetter = !!jobOffer.NeedCoverLetter

                    this.offerNeedBehance =     !!jobOffer.NeedBehance
                    this.offerNeedDribble =     !!jobOffer.NeedDribble
                    this.offerNeedGithub =      !!jobOffer.NeedGithub
                    this.offerNeedInstagram =   !!jobOffer.NeedInstagram

                    this.offerNeedTwitter =     !!jobOffer.NeedTwiter
                    this.offerNeedYoutube =     !!jobOffer.NeedYoutube

                    this.offerSalaryConfidential = !!jobOffer.SalaryConfidential
                    this.offerSponsorVisa = !!jobOffer.SponsorVisa
                    this.offerRemote = !!jobOffer.Remote
                    this.offerSalaryFrom = jobOffer.SalaryFrom
                    this.offerSalaryTo = jobOffer.SalaryTo
                    this.offerEquityFrom = jobOffer.EquityFrom
                    this.offerEquityTo = jobOffer.EquityTo

                    //let curUserId = useAuth.getUserId();

                    if(this.loggedIn) {
                        this.getFullProfileData()
                    }

                    this.offerCategory = this.offerCategories.find(
                        c => c.value === jobOffer.Category.toString()
                    )
                    this.offerDepartment = this.offerDepartments.filter(
                        c => c.value === jobOffer.Department.toString()
                    )
                    this.offerContractType = this.offerContractTypes.find(
                        c => c.value === jobOffer.ContractType.toString()
                    )
                    this.offerSeniorityLevel = this.offerSeniorityLevels.find(
                        c => c.value === jobOffer.SeniorityLevel.toString()
                    )
                    this.offerRequiredSkills = Object.values(jobOffer.RequiredSkills)
                    this.offerCountry = this.offerCountries.find(
                        c => c.value === jobOffer.Country
                    )
                    this.loaded = true
                })
                .catch(err => {
                    console.log(err.message)
                    this.$router.push({name: 'error-404'});
                })
        },
        openLink(url) {
            window.open(url, '_blank')
        },
        onFilePick: function () {
            this.$refs['filePicker'].$refs.input.click()
        },
        onPortfolioFilePick: function () {
            this.$refs['portfolioFilePicker'].$refs.input.click()
        },
        onFileDelete: function () {
            this.file = null
            this.fileName = ''
            this.portfolioFileId = null
        },
        onPortfolioFileDelete: function () {
            this.portfolioFile = null
            this.portfolioFileName = ''
        },
        getFullProfileData(){
            this.$http.get(`candidates/profile?full=1`).then(({data}) => {

                this.profile = data.data
                if(this.profile.freelancer){
                    this.personalDetails.Youtube = this.profile.freelancer.Youtube;
                    this.personalDetails.Behance = this.profile.freelancer.Behance;
                    this.personalDetails.Dribble = this.profile.freelancer.Dribble;
                    this.personalDetails.Github = this.profile.freelancer.Github;
                    this.personalDetails.Instagram = this.profile.freelancer.Instagram;

                    if(this.profile.freelancer && this.profile.freelancer.CvObj){
                        this.portfolioFileName = this.profile.freelancer.CvObj.Label;
                        this.portfolioFileId= this.profile.freelancer.CvObj.Id;
                    }
                }
                console.log("Profile data: ", this.profile);
                //this.cvLink = this.profile.CvLink
            })

            this.$http.post('users/publicLogin').then( ({data}) => {
                this.previewProfile = data.data.menu.user;
            });
        },

        getCultures() {
            this.$http.get(`system/cultures`).then(({data}) => {
                this.cultures = dictToSelectArray(data.data)
            })
        },
        getOfferCategories() {
            this.$http.get(`system/jobCategories`).then(({data}) => {
                this.offerCategories = dictToSelectArray(data.data)
            })
        },
        getOfferContractTypes() {
            this.$http.get(`system/contractTypes`).then(({data}) => {
                this.offerContractTypes = dictToSelectArray(data.data)
            })
        },
        getOfferSeniorityLevels() {
            this.$http.get(`system/seniorityLevels`).then(({data}) => {
                this.offerSeniorityLevels = dictToSelectArray(data.data)
            })
        },
        getOfferDepartments() {
            this.$http.get(`system/departments`).then(({data}) => {
                this.offerDepartments = dictToSelectArray(data.data)
            })
        },
        getOfferCountries() {
            this.$http.get(`system/countries`).then(({data}) => {
                this.offerCountries = dictToSelectArray(data.data)
            })
        },
        getNameByCategoryId(categoryId) {
            const category = this.offerCategories.find(cat => cat.value == categoryId)
            return category ? category.text : ''
        },
        getNameByDepartmentId(departmentId) {
            const department = this.offerDepartments.find(
                dep => dep.value == departmentId
            )
            return department ? department.text : ''
        },
        getNameByContractTypeId(contractTypeId) {
            const contractType = this.offerContractTypes.find(
                con => con.value == contractTypeId
            )
            return contractType ? contractType.text : ''
        },
        getNameByCountryId(countryId) {
            const country = this.offerCountries.find(con => con.value == countryId)
            return country ? country.text : ''
        },
        getNameSeniorityLevelId(levelId) {
            const level = this.offerSeniorityLevels.find(lev => lev.value == levelId)
            return level ? level.text : ''
        },
        validationForm(event) {
            let self = this;
            event.preventDefault()
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    const formData = new FormData()
                    formData.append('offer_id', this.offerId)

                    if (!this.loggedIn) {
                        formData.append('FirstName', this.personalDetails.FirstName)
                        formData.append('LastName', this.personalDetails.LastName)
                        formData.append('Email', this.personalDetails.Email)
                    }

                    //SM Links

                    if(this.offerNeedYoutube){
                        formData.append('Youtube', this.personalDetails.Youtube)
                    }
                    if(this.offerNeedGithub){
                        formData.append('Github', this.personalDetails.Github)
                    }
                    if(this.offerNeedInstagram){
                        formData.append('Instagram', this.personalDetails.Instagram)
                    }
                    if(this.offerNeedTwitter){
                        formData.append('Twitter', this.personalDetails.Twitter)
                    }
                    if(this.offerNeedBehance){
                        formData.append('Behance', this.personalDetails.Behance)
                    }
                    if(this.offerNeedDribble){
                        formData.append('Dribble', this.personalDetails.Dribble)
                    }


                    if (this.offer.Questions){
                        let answerObjects = [];
                        this.offer.Questions.forEach((question, index) => {
                            answerObjects.push({
                                QuestionId: question.id,
                                Answer: self.offerAnswers[index]
                            })
                        });
                        formData.append('Answers', JSON.stringify(answerObjects));
                    }

                    if (this.file) formData.append('apply_letter', this.file)
                    if (this.portfolioFile) formData.append('apply_cv', this.portfolioFile)
                    if (this.cvLink) formData.append('CvLink', this.cvLink)
                    if (this.portfolioFileId) formData.append('apply_cv_id', this.portfolioFileId)

                    this.$http
                        .post(
                            `${this.loggedIn ? 'offers/apply' : 'offers/publicApply'}`,
                            formData
                        )
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })

                            this.$bvModal.hide('modal-apply')
                        })
                        .catch(ex => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(ex),
                                    icon: 'InfoIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.section-title {
    font-size: 11px;
}

.document-name {
    display: inline;
    line-height: 32px;
}

.document-actions-bar {
    float: right;
}

.card .card-header {
    justify-content: left;
    border-bottom: 5px solid var(--primary);
    margin-bottom: 10px;
}

.card .card-header h4 {
    margin-bottom: 0 !important;
}

.comp-logo {
    max-height: 60px;
    margin-right: 20px;
}

.comp-links {
    margin-left: auto;
}

.comp-links svg {
    margin-left: 10px;
}
</style>
